import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)
//解决VUE中重复点击路由遇到Error: Avoided redundant navigation to current location:报错显示是路由重复，加入以下代码即可。
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this,to).catch(err => err)
}

const routes = [
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: "/",
  routes
})

router.afterEach((to, from) => {
  document.documentElement.scrollTop = 0
})


//路由导航
router.beforeEach((to,from,next) =>{
  //初始化左侧菜单
  if(store.state.menuAll.length < 1){
    store.dispatch('initMenu');
  }
  next();
})

export default router
