import Vue from 'vue'
const MyCommon = {
  //获取当前时间，并返回 格式如2024/01/01-01:01:01
  getTime() {
    const timeStamp = new Date().getTime();
    const date = new Date(timeStamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const formattedDate = year + '/' + month + '/' + day + '-' + hours + ':' + minutes + ':' + seconds;
    return formattedDate
  },
  //时间格式转 yyyy-MM-DD 并且不够两位数 + 0
  formatDate(date) {
    const year = date.getFullYear(); // 获取年份
    const month = (date.getMonth() + 1).toString().length < 2 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1); // 获取月份，判断是否为两位数
    const day = date.getDate().toString().length < 2 ? '0' + date.getDate() : date.getDate(); // 获取日期，判断是否为两位数
    return year + '-' + month + '-' + day; // 返回格式化后的日期字符串
  },
  getUserInfo(vue) {
    vue.$webRequest({
      method: 'get',
      url: '/api/user/user_find'
    }).then((res)=>{
      if(res.data.code === 200){
        let localUserInfo = res.data.user_find
        localStorage.setItem("userInfo", JSON.stringify(localUserInfo));
        window.location = '/home';
      }else{
        vue.$message({
          message: res.data.msg,
          type: 'warning'
        });
      }
    }).catch((err) => {})
  },
  //判断URL用对应的触发对应的
  handleLink(vue, url) {
    if(url) {
      if(url.indexOf('http') > -1) {
        window.open(url, '_blank')
      }
      else {
        vue.$router.push(url)
      }
    }
  }
}

export default MyCommon