<template>
  <div id="app">
    <HeadBar :isPC="isPC" :pageData="pageData" @triggerGetPageData="getPageData"></HeadBar>
    <div style="width: 100%;height: 70px;"></div>
    <router-view :isPC="isPC" :pageData="pageData" :key="key"/>
    <FooterBar :pageData="pageData"></FooterBar>
  </div>
</template>

<script>
// import "@/assets/css/public.css"
import HeadBar from './components/HeadBar.vue';
import FooterBar from './components/FooterBar.vue';
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      isPC: true,
      pageData: {}
    }
  },
  created() {
    this.getPageData()
  },
  mounted() {
    this.$nextTick(() =>{
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    });
  },
  components: {
    HeadBar,
    FooterBar
  },
  methods: {
    //屏幕实时响应切换pc or m header
    handleResize() {
      this.screenWidth = window.innerWidth
      if(innerWidth <= 768) {
        this.isPC = false
      }else {
        this.isPC = true
      }
    },
    getPageData() {
      this.$webRequest({
        method: 'get',
        url: '/index/other/other_all'
      }).then((res) => {
        this.pageData =  res.data
        //动态修改浏览器标签title和icon
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.href = this.pageData.home_image_all[20].home_image_picture
        document.getElementsByTagName('head')[0].appendChild(link);
        document.getElementsByTagName("title")[0].innerText = this.pageData.home_image_all[20].home_image_title
      })
    }
  },
  computed: { 
    // ...mapState(['isResponse']),
    key() { 
      return this.$route.name !== undefined? this.$route.name + +new Date(): this.$route + +new Date() 
    }
  },
}
</script>

<style lang="scss">

</style>
