import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import webRequest from "../utils/WebRequest"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //登录状态
    isLogin: false,
    //菜单数据
    Lang: window.localStorage.getItem('Lang') || 'zh',
    menuAll: [],
  },
  getters: {
  },
  mutations: {
    //设置登录状态
    setIsLogin(state,data){
      state.isLogin = data;
    },
    /*****菜单数据*****/
    setMenu(state,data){
      state.menuAll = data;
      /***动态路由处理***/
      //获取旧的路由
      let oldRouter = router.options.routes;
      //后台返回的菜单数据
      let menuList = state.menuAll;
      
      //定义静态路由
      let routerArr = [
        {
          name: '*',
          path: '*',
          component:resolve => require(['@/views/NotFoundPage.vue'],resolve)
        },
        {
          name: '/',
          path: '/',
          component:resolve => require(['@/views/HomePage.vue'],resolve)
        },
        {
          name: 'home',
          path: '/home',
          component:resolve => require(['@/views/HomePage.vue'],resolve)
        },
        {
          name: 'NewsContent',
          path: '/NewsContent',
          component:resolve => require(['@/views/NewsContentPage.vue'],resolve)
        }
      ];
      //遍历菜单，重新组装成可用的动态路由格式
      //一级菜单
      menuList.forEach(item=> {
        //重组一级菜单
        let menuParent = {
          name:item.category_label,
          path:item.category_path,
          component:resolve => require([`@/views${item.category_component}.vue`],resolve),
          children: []
        }
        //重组二级菜单
        if(item.children){
          item.children.forEach((item2, index2) => {
            let menuChild = {
              name:item2.category_label,
              path:item2.category_path,
              component:resolve => require([`@/views${item2.category_component}.vue`],resolve)
            }
            menuParent.children.push(menuChild);

            //重组三级菜单
            if(item2.children){
              menuParent.children[index2].children = []
              item2.children.forEach(item3 => {
                let menuChild2 = {
                  name:item3.category_label,
                  path:item3.category_path,
                  component:resolve => require([`@/views${item3.category_component}.vue`],resolve)
                }
                menuParent.children[index2].children.push(menuChild2);
              })
            }
          })
        }
        routerArr.push(menuParent);
      });
      //遍历重新组合的路由数组合并新旧路由
      routerArr.forEach(item => {
        oldRouter.push(item);
      })
      //添加路由
      for (let x of oldRouter) {
        router.addRoute(x);
      } 
    },
    setMenuClick(state,data){
      state.menuClick = data;
    },
    setLang(state,data) {
      state.Lang = data
    }
  },
  actions: {
    /*****异步请求菜单数据*****/
    initMenu({commit}){
      //发起Ajax请求
      webRequest({
        method: 'get',
        url: '/index/category/category_all'
      }).then((res) => {
        //把菜单数据存储到vuex中
        commit('setMenu',res.data.category_all);
      })

      //请求页面设置
      // webRequest({
      //   method: 'get',
      //   url: '/index/SiteSetup/site_all'
      // }).then((res) => {
      //   // 把页面数据存在vuex中
      //   // 存首页数据
      //   // const site_setup_all = {site_setup_all: res.data.site_setup_all}
      //   const site_setup_all =  res.data.site_setup_all
      //   commit('setPageData', site_setup_all);
      // })
    }
  },
  modules: {
  }
})
