import axios from 'axios'
import { Message } from 'element-ui'

//创建axios实例对象
let service = axios.create({
    // baseURL: 'https://oa.api-web.top/',//测试服务器地址
    baseURL: 'http://rxgw.acartool.com/',//线上服务器地址
    timeout: 5000//默认超时时长
});


//请求拦截器
service.interceptors.request.use(
    //请求成功处理
    config => {
        return config;
    },
    //请求失败处理
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器
service.interceptors.response.use(
    //响应成功处理
    response => {
        return response;
    },
    //响应失败处理
    error => {
        if(error.request.status === 500){
            Message.error({message:'Service response failed'});
        }
        if(error.request.status === 0){
            Message.error({message:'Interface response failed'});
        }
        return Promise.reject(error);
    }
)

//封装axios请求方法
async function loginRequest(option = {}) {
    let result = [];
    if(option.method === 'post'){
        //处理post请求
        await service[option.method](option.url,option.data)
        .then((res) => {
            result = res;
        })
        .catch((err) => {
            result = err;
        })
    }
    return result;
}

//封装axios请求方法暴露出去
export default loginRequest
