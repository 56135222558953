import axios from 'axios'
import { Message,Loading } from 'element-ui'
import store from '../store/index'

//创建axios实例对象
let service = axios.create({
    // baseURL: 'https://oa.api-web.top/',//测试服务器地址
    baseURL: 'http://rxgw.acartool.com/',//线上服务器地址
    timeout: 30000//默认超时时长
});

//loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
function startLoading() {
  loading = Loading.service({
    lock: false,
    text: 'Loading...',
    background: 'rgba(0, 0, 0, 0.0)',
    spinner: 'el-icon-loading'
  });
}

function endLoading() {
  loading.close();
}

//声明一个对象用于存储请求个数
let needLoadingRequestCount = 0;
//启动
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
}
//关闭
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading();
    }
}

//请求拦截器
service.interceptors.request.use(
    //请求成功处理
    config => {
        showFullScreenLoading();
        //如果token存在，则把token放入请求头
		// let token = localStorage.getItem('token');
		// if (token) {
		// 	config.headers['Authorization'] = token;
		// }
		let Pagelang = localStorage.getItem('Lang');
		config.headers['Authorization'] = Pagelang;
        return config;
    },
    //请求失败处理
    error => {
        tryHideFullScreenLoading();
        return Promise.reject(error);
    }
);

//响应拦截器
service.interceptors.response.use(
    //响应成功处理
    response => {
        tryHideFullScreenLoading();
        return response;
    },
    //响应失败处理
    error => {
        tryHideFullScreenLoading();
        if(error.request.status === 500){
            Message.error({message:'Service response failed'});
        }
        if(error.request.status === 0){
            Message.error({message:'Interface response failed'});
        }
        return Promise.reject(error);
    }
)

//封装axios请求方法
async function webRequest(option = {}) {
    let result = [];
    if(option.method === 'get'){
        //处理get请求
        await service[option.method](option.url,{params:option.data})
        .then((res) => {
            result = res;
        })
        .catch((err) => {
            result = err;
        })
    }
    if(option.method === 'post'){
        //处理post请求
        await service[option.method](option.url,option.data)
        .then((res) => {
            result = res;
        })
        .catch((err) => {
            result = err;
        })
    }
    return result;
}

//封装axios请求方法暴露出去
export default webRequest
