<template>
  <div class="footerBarBox">
    <div class="publicContent">
      <div class="autoPublicContent">
        <div class="footerBarCont">
          <div class="left">
            <div class="cont" 
            v-for="(item, index) in menuAll" 
            :key="index"
            v-if="(item.category_component != '/HomePage') && (item.category_component != '/ContactPage')"
            >
              <div class="top" @click="$router.push(item.category_path)">{{ item.category_name }}</div>
              <p v-if="item.children" v-for="(item2, index2) in item.children" :key="index2" @click="$router.push(item2.category_path)">{{ item2.category_name }}</p>
            </div>
          </div>
          <div class="right" v-if="pageDataArr">
            <!-- <div class="top">联系我们 <span>ontact us</span></div> -->
            <div class="top">{{ pageDataArr[6].home_image_title }}</div>
            <p>
              <svg t="1706693910388" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6423" width="20" height="20"><path d="M528 32c-202.944 0-368 164.8-368 367.36 0 75.2 22.656 147.584 65.024 208.48 2.112 3.648 4.256 7.168 6.784 10.592l268.608 353.472C507.712 980 517.504 984.48 528 984.48c10.368 0 20.224-4.512 28.768-14.08l267.36-352c2.624-3.52 4.896-7.36 6.112-9.6A364.864 364.864 0 0 0 896 399.36C896 196.8 730.912 32 528 32z m0 498.72a131.52 131.52 0 0 1-131.456-131.232 131.488 131.488 0 0 1 262.88 0A131.52 131.52 0 0 1 528 530.688z" p-id="6424" fill="#a0a0a0"></path></svg>
              {{ pageDataArr[3].home_image_title }}</p>
            <p>
              <svg t="1706693979452" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8639" width="20" height="20"><path d="M282.4 460C344 580.8 443.2 680 564 741.6L657.6 648c11.2-11.2 28.8-15.2 43.2-10.4 48 16 100 24 152.8 24 23.2 0 42.4 19.2 42.4 42.4v148.8c0 23.2-19.2 42.4-42.4 42.4C452.8 896 128 571.2 128 170.4c0-23.2 19.2-42.4 42.4-42.4H320c23.2 0 42.4 18.4 42.4 41.6 0 53.6 8.8 104.8 24 152.8 4.8 15.2 1.6 31.2-10.4 43.2L282.4 460z" p-id="8640" fill="#a0a0a0"></path></svg>
              {{ pageDataArr[4].home_image_title }}</p>
            <p>
              <svg t="1706694008491" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10604" width="20" height="20"><path d="M153.236734 163.111422l714.763665 0c48.810633 0 88.718697 38.475467 89.332667 85.853503l-446.458679 242.518237L64.313381 249.169581C64.722694 201.689218 104.323773 163.111422 153.236734 163.111422L153.236734 163.111422zM64.313381 341.981413l-0.409313 429.062856c0 47.787349 40.215049 86.876786 89.332667 86.876786l714.763665 0c49.117618 0 89.332667-39.089437 89.332667-86.876786L957.333067 341.776756 521.311882 573.038873c-6.651344 3.581493-14.735285 3.581493-21.386629 0L64.313381 341.981413 64.313381 341.981413zM64.313381 341.981413" p-id="10605" fill="#a0a0a0"></path></svg>
              {{ pageDataArr[5].home_image_title }}</p>
          </div>
        </div>
        <div class="copyright" v-if="pageDataArr">
          <p>{{ pageDataArr[10].home_image_title }} <span v-html="pageDataArr[11].home_image_title"></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {mapState} from "vuex"
export default {
  name: 'FooterBar',
  props: {
    pageData: Object
  },
  data() {
    return {
      menuAll: this.$store.state.menuAll,
      pageDataArr: []
    }
  },
  created() {
    this.pageDataArr = this.pageData.home_image_all
  },
  computed: {
    // ...mapState(['menuAll']),
  },
  watch: {
    pageData(newValue) {
      // this.pageData = newValue
      this.pageDataArr = newValue.home_image_all
    },
    '$store.state.menuAll'(newValue) {
      this.menuAll = newValue
    },
  }
}
</script>

<style lang="scss" scoped>
  .footerBarBox {
    .publicContent {
      background-color: #212121; 
      .footerBarCont {
        padding: 60px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p, .top {
          margin: 0;
          color: #dddddd;
          text-align: center;
        }
        .left {
          margin-right: 60px;
          flex: 1;
          display: flex;
          flex-direction: row;
          .cont {
            flex: 1;
            .top {
              font-size: 20px;
              color: #a0a0a0;
              font-weight: 700;
              border-bottom: 2px solid #a0a0a0;
              // padding-bottom: 15px;
              margin-bottom: 20px;
              cursor: pointer;
              display: flex;
              height: 4rem;
              align-items: center;
              justify-content: center;
            }
            p {
              margin: 10px 0;
              cursor: pointer;
              color: #a8a8a8;
            }
            p:hover, .top:hover {
              color: #fff;
            }
          }
        }
        .right {
          max-width: 30%;
          border-left: 2px solid #a0a0a0;
          padding-left: 30px;
          display: flex;
          flex-direction: column;
          .top {
            font-size: 20px;
            color: #a0a0a0;
            font-weight: 700;
            text-align: left;
            margin-bottom: 2rem;
            // margin-left: 1.7rem;
            // padding-left: 1.7rem;
            position: relative;
            span {
              font-size: 20px;
              font-weight: 500;
              color: #a0a0a0;
              position: absolute;
              left: 1.6rem;
              bottom: -1.3rem;
            }
          }
          // .top::before {
          //   content: '';
          //   width: 1.3rem;
          //   height: 1.6rem;
          //   border-radius: 6px;
          //   background-color: #888888;
          //   position: absolute;
          //   top: 0.9rem;
          //   left: 0;
          // }
          // .top::after {
          //   content: '';
          //   width: 1.4rem;
          //   height: 1.2rem;
          //   border-radius: 5px;
          //   background-color: #212121;
          //   position: absolute;
          //   top: 1.08rem;
          //   left: 0.2rem;
          // }
          p {
            display: flex;
            align-items: center;
            text-align: left;
            margin: 10px 0;
            color: #a0a0a0;
            svg {
              min-width: 25px;
              margin-right: 8px;
            }
          }
        }
      }
      .copyright {
        p {
          margin: 0;
          border-top: 1px solid #5a5a5a;
          padding: 1rem 0;
          color: #888;
          text-align: center;
          span {color: #888;}
          a:hover { color: #eee !important; }
        }
      }
    }
  }


  
  @media screen and (max-width: 768px) {
    .footerBarCont {
      padding: 30px 0 !important;
    }
    .footerBarCont {
      flex-direction: column !important;
      .left {
        flex-direction: column !important;
        margin-right: 0 !important;
        .cont {
          display: flex;
          flex-wrap: wrap;
          .top {
            width: 100% !important;
            font-size: 16px !important;
            margin-bottom: 5px !important;
            padding-bottom: 5px !important;
            border-bottom: 1px solid #5a5a5a !important;
            margin-top: 10px;
            text-align: left;
            display: inline !important;
            height: inherit !important;
          }
          p {
            margin: 5px 0 !important;
            margin-right: 10px !important;
          }
        }
      }
      .right {
        max-width: 100% !important;
        border-left: none !important;
        padding-left: 0 !important;
        .top {
          // width: 100% !important;
          font-size: 16px !important;
          margin-bottom: 5px !important;
          padding-bottom: 0.6rem !important;
          border-bottom: 1px solid #5a5a5a !important;
          margin-top: 10px !important;
          text-align: left;
          span {
            font-size: 16px !important;
            left: 1.6rem !important;
            bottom: 0.6rem !important;
          }
          img {
            height: 25px;
          }
        }
      }
    }
  }
</style>